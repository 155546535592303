import React from 'react';
import Layout from '../../components/common/pageLayout';
import { toast } from 'react-toastify';
import { v3_add_new_entry } from '../../actions/v3/ledgerAndCashbook';
import constant from '../../constant';
import Form from '../../components/LedgerAndCashbook/paymentEntry';

const PaymentEntry = (props) => {
    const onSubmit = (model) => {
        v3_add_new_entry(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message);
                props.history.push(constant.Authedcomponent.paymentList.url); // Redirect to payment list
            } else {
                toast.error(res.error);
            }
        });
    };

    const onCancel = () => {
        props.history.push(constant.Authedcomponent.paymentList.url); // Redirect to payment list on cancel
    };

    return (
        <React.Fragment>
            <Layout title={'New Payment Entry'}>
                <Form
                    onCancel={onCancel}
                    title={'New Payment Entry'}
                    onSubmit={onSubmit}
                    buttonName={'Create Entry'}
                />
            </Layout>
        </React.Fragment>
    );
};

export default PaymentEntry;
