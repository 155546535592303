import React, { useEffect, useState } from 'react'
import { validateFunctionalForm } from '../../utils';
import moment from 'moment';
import { v2_voucher_type } from '../../actions/v2/masterData';
import { v2_products, v2_sub_products } from '../../actions/v2/product';
import { v2_transporter_vehicles, v2_transporters } from '../../actions/v2/transporter';
import { v2_gates } from '../../actions/v2/gate';
import { v2_warehouse } from '../../actions/v2/warehouse';
import { v2_companies } from '../../actions/v2/company';
import { NumericFormat } from 'react-number-format';


const Form = (props) => {
    const [state, setState] = useState({
        date: { name: 'date', value: props.data ? props.data.DATE : moment().format('YYYY-MM-DD'), isRequired: true, error: '' },
        voucherType: { name: 'voucherType', value: props.data ? props.data.VOUCHER_TYPE_ID : '', options: [], isRequired: true, error: '' },
        gateNumber: { name: 'gateNumber', value: props.data ? props.data.GATE_ID : '', options: [], isRequired: true, error: '' },
        rstNumber: { name: 'rstNumber', value: props.data ? props.data.RST_NUMBER : '', isRequired: true, error: '' },
        invoiceNumber: { name: 'invoiceNumber', value: props.data ? props.data.INVOICE_NUMBER : '', isRequired: false, error: '' },
        product: { name: 'product', value: props.data ? props.data.PRODUCT_ID : '', options: [], isRequired: true, error: '' },
        subProduct: { name: 'subProduct', value: props.data ? props.data.SUB_PRODUCT_ID : '', options: [], isRequired: true, error: '' },
        challanWeight: { name: 'challanWeight', value: props.data ? props.data.CHALLAN_WEIGHT : '', isRequired: true, error: '' },
        vehicle: { name: 'vehicle', value: props.data ? props.data.VEHICLE_ID : '', options: [], isRequired: true, error: '' },
        transporter: { name: 'transporter', value: props.data ? props.data.TRANSPORTER_ID : '', options: [], isRequired: true, error: '' },
        warehouse: { name: 'warehouse', value: props.data ? props.data.WAREHOUSE_ID : '', options: [], isRequired: true, error: '' },
        invoiceRate: { name: 'invoiceRate', value: props.data ? props.data.INVOICE_RATE : '', isRequired: true, error: '' },
        actualRate: { name: 'actualRate', value: props.data ? props.data.ACTUAL_RATE : '', isRequired: true, error: '' },
        actualWeight: { name: 'actualWeight', value: props.data ? props.data.ACTUAL_WEIGHT : '', isRequired: true, error: '' },

        party: { name: 'party', value: props.data ? props.data.PARTY_ID : '', options: [], isRequired: true, error: '' },
        mill: { name: 'mill', value: props.data ? props.data.MILL_ID : '', options: [], isRequired: true, error: '' },
        source: { name: 'source', value: props.data ? props.data.SOURCE_ID : '', options: [], isRequired: true, error: '' },
        amount: { name: 'amount', value: props.data ? props.data.AMOUNT : '', isRequired: true, error: '' },
        gst: { name: 'gst', value: props.data ? props.data.GST : '', isRequired: true, error: '' },
        grandTotal: { name: 'grandTotal', value: props.data ? props.data.GRAND_TOTAL : '', isRequired: true, error: '' },
        sourceInvoiceNumber: { name: 'sourceInvoiceNumber', value: props.data ? props.data.SOURCE_INVOICE_NUMBER : '', isRequired: true, error: '' },
        weightLoss: { name: 'weightLoss', value: props.data ? props.data.WEIGHT_LOSS : '', isRequired: false, error: '' },
        paymentDate: { name: 'paymentDate', value: props.data ? props.data.PAYMENT_DATE : '', isRequired: false, error: '' },
        paymentAmount: { name: 'paymentAmount', value: props.data ? props.data.PAYMENT_AMOUNT : '', isRequired: false, error: '' },
        debitNoteNumber: { name: 'debitNoteNumber', value: props.data ? props.data.DEBIT_NOTE_NUMBER : '', isRequired: false, error: '' },
        debitNote: { name: 'debitNote', value: props.data ? props.data.DEBIT_NOTE : '', isRequired: false, error: '' },
        debitNoteGst: { name: 'debitNoteGst', value: props.data ? props.data.DEBIT_NOTE_GST : '', isRequired: false, error: '' },
        gstPending: { name: 'gstPending', value: props.data ? props.data.GST_PENDING : '', isRequired: false, error: '' },
        balancePending: { name: 'balancePending', value: props.data ? props.data.BALANCE_PENDING : '', isRequired: false, error: '' },
        netBalancePending: { name: 'netBalancePending', value: props.data ? props.data.NET_BALANCE_PENDING : '', isRequired: false, error: '' },
        tds: { name: 'tds', value: props.data ? props.data.TDS : '', isRequired: false, error: '' },
    })

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: prevState[name].isRequired && !value ? 'This field is required' : ''
            }
        }));
    };

    const onSubmit = (e) => {
        debugger
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                date: state.date.value,
                rstNumber: state.rstNumber.value,
                invoiceNumber: state.invoiceNumber.value,
                product: state.product.value,
                subProduct: state.subProduct.value,
                challanWeight: state.challanWeight.value,
                vehicleId: state.vehicle.value,
                gate: state.gateNumber.value,
                transporter: state.transporter.value,
                voucherType: state.voucherType.value,
                warehouseId: state.warehouse.value,
                invoiceRate: state.invoiceRate.value,
                actualWeight: state.actualWeight.value,
                actualRate: state.actualRate.value,
                amount: state.amount.value,
                gst: state.gst.value,
                grandTotal: state.grandTotal.value,
                weightLoss: state.weightLoss.value,
                paymentDate: state.paymentDate.value,
                paymentAmount: state.paymentAmount.value,
                debitNoteNumber: state.debitNoteNumber.value,
                debitNote: state.debitNote.value,
                debitNoteGst: state.debitNoteGst.value,
                gstPending: state.gstPending.value,
                balancePending: state.balancePending.value,
                netBalancePending: state.netBalancePending.value,
                tds: state.tds.value,
                partyId: state.party.value,
                millId: state.mill.value,
                sourceInvoiceNumber: state.sourceInvoiceNumber.value,
                sourceId: state.source.value

            };

            if (props.data) {
                model.id = props.data.ID
            }
            props.onSubmit(model)
        }
    }
    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    };
    useEffect(() => {
        v2_voucher_type().then((res) => {
            if (res.status) {
                setOptions(state.voucherType.name, res.result)
            }
        })

        v2_products().then((res) => {
            if (res.status) {
                setOptions(state.product.name, res.result)
            }
        })
        v2_transporters().then((res) => {
            if (res.status) {
                setOptions(state.transporter.name, res.result);
            }
        })


        v2_warehouse().then((res) => {
            if (res.status) {
                setOptions('warehouse', res.result);
            }
        })
        v2_companies().then((res) => {
            if (res.status) {
                setOptions('party', res.result);
                setOptions('mill', res.result);
                setOptions('source', res.result);

            }
        })


    }, [])

    useEffect(() => {
        if (state.product.value) {
            v2_sub_products(state.product.value).then((res) => {
                if (res.status) {
                    setOptions(state.subProduct.name, res.result)
                }
            })
        }
        else {
            setState(prevState => ({
                ...prevState,
                [state.subProduct.name]: {
                    ...prevState[state.subProduct.name],
                    value: ''
                }
            }));
            setOptions(state.subProduct.name, [])

        }
    }, [state.product.value])
    useEffect(() => {
        if (state.warehouse.value) {
            v2_gates(state.warehouse.value).then((res) => {
                if (res.status) {
                    setOptions(state.gateNumber.name, res.result);
                }
            })
        }
        else {
            setState(prevState => ({
                ...prevState,
                [state.gateNumber.name]: {
                    ...prevState[state.gateNumber.name],
                    value: ''
                }
            }));
            setOptions(state.gateNumber.name, [])

        }
    }, [state.warehouse.value])

    useEffect(() => {
        if (state.voucherType.value == '3') {
            const actualWeight = isNaN(state.actualWeight.value) ? 0 : state.actualWeight.value;
            const actualRate = isNaN(state.actualRate.value) ? 0 : state.actualRate.value;

            const value = actualWeight * actualRate;

            setState(prevState => ({
                ...prevState,
                [state.amount.name]: {
                    ...prevState[state.amount.name],
                    value: value
                }
            }));
        }
        if (state.voucherType.value == '4') {
            const invoiceRate = isNaN(state.invoiceRate.value) ? 0 : state.invoiceRate.value;
            const challanWeight = isNaN(state.challanWeight.value) ? 0 : state.challanWeight.value;

            const value = invoiceRate * challanWeight;

            setState(prevState => ({
                ...prevState,
                [state.amount.name]: {
                    ...prevState[state.amount.name],
                    value: value
                }
            }));
        }

    }, [state.actualWeight.value, state.actualRate.value, state.challanWeight.value,state.invoiceRate.value]);

    useEffect(() => {

        // Validate and get the amount and gst, defaulting to 0 if not a number
        const amount = isNaN(state.amount.value) ? 0 : state.amount.value;
        const gst = isNaN(state.gst.value) ? 0 : state.gst.value;
        const tds = isNaN(state.tds.value) ? 0 : state.tds.value;

        // Calculate the grand total
        const value = amount + gst + tds;

        // Update the state with the calculated grand total
        setState(prevState => ({
            ...prevState,
            [state.grandTotal.name]: {
                ...prevState[state.grandTotal.name],
                value: value
            }
        }));

    }, [state.gst.value, state.amount.value, state.tds.value]);

    useEffect(() => {
        // Validate and get the amount and gst, defaulting to 0 if not a number
        const challanWeight = isNaN(state.challanWeight.value) ? 0 : state.challanWeight.value;
        const actualWeight = isNaN(state.actualWeight.value) ? 0 : state.actualWeight.value;

        // Calculate the grand total
        const value = challanWeight - actualWeight;

        // Update the state with the calculated grand total
        setState(prevState => ({
            ...prevState,
            [state.weightLoss.name]: {
                ...prevState[state.weightLoss.name],
                value: value
            }
        }));
    }, [state.challanWeight.value, state.actualWeight.value]);


    useEffect(() => {
        const voucherType = state.voucherType.value;
        if (voucherType == '3') {
            // sale
            // Validate and get the actualWeight and invoiceRate, defaulting to 0 if not a number
            const actualWeight = isNaN(state.actualWeight.value) ? 0 : state.actualWeight.value;
            const invoiceRate = isNaN(state.invoiceRate.value) ? 0 : state.invoiceRate.value;

            // Find the selected subProduct option and get its GST_PERCENT
            const selectedSubProduct = state.subProduct.options.find((item) => item.ID === state.subProduct.value);
            const gstPercent = selectedSubProduct ? selectedSubProduct.GST_PERCENT : 0;

            // Calculate the value with GST applied
            const value = actualWeight * invoiceRate * (gstPercent / 100);

            // Update the state with the calculated value
            setState(prevState => ({
                ...prevState,
                [state.gst.name]: {
                    ...prevState[state.gst.name],
                    value: value
                }
            }));
        }
        else {
            // Validate and get the actualWeight and invoiceRate, defaulting to 0 if not a number
            const challanWeight = isNaN(state.challanWeight.value) ? 0 : state.challanWeight.value;
            const invoiceRate = isNaN(state.invoiceRate.value) ? 0 : state.invoiceRate.value;

            // Find the selected subProduct option and get its GST_PERCENT
            const selectedSubProduct = state.subProduct.options.find((item) => item.ID === state.subProduct.value);
            const gstPercent = selectedSubProduct ? selectedSubProduct.GST_PERCENT : 0;

            // Calculate the value with GST applied
            const value = challanWeight * invoiceRate * (gstPercent / 100);

            // Update the state with the calculated value
            setState(prevState => ({
                ...prevState,
                [state.gst.name]: {
                    ...prevState[state.gst.name],
                    value: value
                }
            }));
        }

    }, [state.actualWeight.value, state.challanWeight.value, state.invoiceRate.value, state.subProduct.value, state.subProduct.options]);



    useEffect(() => {
        if (state.transporter.value) {
            v2_transporter_vehicles(state.transporter.value).then((res) => {
                if (res.status) {
                    setOptions(state.vehicle.name, res.result)
                }
            })
        }
        else {
            setState(prevState => ({
                ...prevState,
                [state.vehicle.name]: {
                    ...prevState[state.vehicle.name],
                    value: ''
                }
            }));
            setOptions(state.vehicle.name, [])

        }
    }, [state.transporter.value])
    return (
        <React.Fragment>
            <h4 className="card-title mb-4">{props.title}</h4>
            <div id="basic-example">
                <section>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-date-input">
                                        Date {state.date.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <input
                                        type="date"
                                        className={state.date.error ? "form-control is-invalid" : "form-control"}
                                        id="basicpill-date-input"
                                        name={state.date.name}
                                        value={state.date.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-voucherType-input">
                                        Voucher Type {state.voucherType.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-voucherType-input"
                                        className={state.voucherType.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.voucherType.name}
                                        value={state.voucherType.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Voucher Type</option>
                                        {state.voucherType.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-warehouse-input">
                                        Warehouse Number {state.warehouse.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-warehouse-input"
                                        className={state.warehouse.error ? "form-select is-invalid" : "form-select"}
                                        name={state.warehouse.name}
                                        value={state.warehouse.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Warehouse</option>
                                        {state.warehouse.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-gateNumber-input">
                                        Gate Number {state.gateNumber.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-gateNumber-input"
                                        className={state.gateNumber.error ? "form-select is-invalid" : "form-select"}
                                        name={state.gateNumber.name}
                                        value={state.gateNumber.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Gate</option>
                                        {state.gateNumber.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-invoiceNumber-input">
                                        Invoice Number {state.invoiceNumber.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <input
                                        type="text"
                                        className={state.invoiceNumber.error ? "form-control is-invalid" : "form-control"}
                                        id="basicpill-invoiceNumber-input"
                                        name={state.invoiceNumber.name}
                                        value={state.invoiceNumber.value}
                                        placeholder='Enter Invoice Number'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-rstNumber-input">
                                        RST Number
                                        {state.rstNumber.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <input
                                        type="text"
                                        className={state.rstNumber.error ? "form-control is-invalid" : "form-control"}
                                        id="basicpill-rstNumber-input"
                                        name={state.rstNumber.name}
                                        value={state.rstNumber.value}
                                        placeholder='Enter RST Number'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-transporter-input">
                                        Transporter {state.transporter.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-transporter-input"
                                        className={state.transporter.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.transporter.name}
                                        value={state.transporter.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Transporter</option>
                                        {state.transporter.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-vehicle-input">
                                        Vehicle {state.vehicle.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-vehicle-input"
                                        className={state.vehicle.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.vehicle.name}
                                        value={state.vehicle.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Vehicle</option>
                                        {state.vehicle.options.map((item) => {
                                            console.log(item)
                                            return (<option value={item.ID}>
                                                {`${item.VEHICLE_NUMBER} (${item.NAME})`}
                                            </option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-product-input">
                                        Product {state.product.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-product-input"
                                        className={state.product.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.product.name}
                                        value={state.product.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Product</option>
                                        {state.product.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-subProduct-input">
                                        Sub Product {state.subProduct.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-subProduct-input"
                                        className={state.subProduct.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.subProduct.name}
                                        value={state.subProduct.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Sub Product</option>
                                        {state.subProduct.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-party-input">
                                        Party Name {state.party.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-party-input"
                                        className={state.party.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.party.name}
                                        value={state.party.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Party Name</option>
                                        {state.party.options.map((item) => {
                                            return (<option value={item.ID}>{item.COMPANY_NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-mill-input">
                                        Mill {state.mill.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-mill-input"
                                        className={state.mill.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.mill.name}
                                        value={state.mill.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Mill</option>
                                        {state.mill.options.map((item) => {
                                            return (<option value={item.ID}>{item.COMPANY_NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-source-input">
                                        Source {state.source.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <select
                                        id="basicpill-source-input"
                                        className={state.source.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.source.name}
                                        value={state.source.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Source</option>
                                        {state.source.options.map((item) => {
                                            return (<option value={item.ID}>{item.COMPANY_NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-sourceInvoiceNumber-input">
                                        Source Invoice Number {state.sourceInvoiceNumber.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <input
                                        type='text'
                                        id="basicpill-sourceInvoiceNumber-input"
                                        className={state.sourceInvoiceNumber.error ? "form-control is-invalid" : "form-control"}
                                        name={state.sourceInvoiceNumber.name}
                                        value={state.sourceInvoiceNumber.value}
                                        placeholder='Enter Source Invoice Number'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-challanWeight-input">
                                        Challan Weight (in Kg) {state.challanWeight.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-challanWeight-input"
                                        className={state.challanWeight.error ? "form-control is-invalid" : "form-control"}
                                        name={state.challanWeight.name}
                                        value={state.challanWeight.value}
                                        placeholder='Enter Weight'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-actualWeight-input">
                                        Actual Weight {state.actualWeight.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-actualWeight-input"
                                        className={state.actualWeight.error ? "form-control is-invalid" : "form-control"}
                                        name={state.actualWeight.name}
                                        value={state.actualWeight.value}
                                        placeholder='Enter Actual Weight'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-weightLoss-input">
                                        Weight Loss {state.weightLoss.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-weightLoss-input"
                                        className={state.weightLoss.error ? "form-control is-invalid" : "form-control"}
                                        name={state.weightLoss.name}
                                        value={state.weightLoss.value}
                                        placeholder='Enter Weight Loss'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-invoiceRate-input">
                                        Invoice Rate (in Rs) {state.invoiceRate.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-invoiceRate-input"
                                        className={state.invoiceRate.error ? "form-control is-invalid" : "form-control"}
                                        name={state.invoiceRate.name}
                                        value={state.invoiceRate.value}
                                        placeholder='Enter Rate'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-actualRate-input">
                                        Actual Rate {state.actualRate.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-actualRate-input"
                                        className={state.actualRate.error ? "form-control is-invalid" : "form-control"}
                                        name={state.actualRate.name}
                                        value={state.actualRate.value}
                                        placeholder='Enter Actual Rate'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>




                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-amount-input">
                                        Amount {state.amount.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-amount-input"
                                        className={state.amount.error ? "form-control is-invalid" : "form-control"}
                                        name={state.amount.name}
                                        value={state.amount.value}
                                        placeholder='Enter Amount'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-gst-input">
                                        GST {state.gst.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-gst-input"
                                        className={state.gst.error ? "form-control is-invalid" : "form-control"}
                                        name={state.gst.name}
                                        value={state.gst.value}
                                        placeholder='Enter GST'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-grandTotal-input">
                                        Grand Total {state.grandTotal.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-grandTotal-input"
                                        className={state.grandTotal.error ? "form-control is-invalid" : "form-control"}
                                        name={state.grandTotal.name}
                                        value={state.grandTotal.value}
                                        placeholder='Enter Grand Total'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>


                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-paymentDate-input">
                                        Payment Date {state.paymentDate.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <input
                                        type='date'
                                        id="basicpill-paymentDate-input"
                                        className={state.paymentDate.error ? "form-control is-invalid" : "form-control"}
                                        name={state.paymentDate.name}
                                        value={state.paymentDate.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-paymentAmount-input">
                                        Payment Amount {state.paymentAmount.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-paymentAmount-input"
                                        className={state.paymentAmount.error ? "form-control is-invalid" : "form-control"}
                                        name={state.paymentAmount.name}
                                        value={state.paymentAmount.value}
                                        placeholder='Enter Payment Amount'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-debitNoteNumber-input">
                                        Debit Note Number {state.debitNoteNumber.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <input
                                        id="basicpill-debitNoteNumber-input"
                                        className={state.debitNoteNumber.error ? "form-control is-invalid" : "form-control"}
                                        name={state.debitNoteNumber.name}
                                        value={state.debitNoteNumber.value}
                                        placeholder='Enter Debit Note Number'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-debitNote-input">
                                        Debit Note {state.debitNote.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-debitNote-input"
                                        className={state.debitNote.error ? "form-control is-invalid" : "form-control"}
                                        name={state.debitNote.name}
                                        value={state.debitNote.value}
                                        placeholder='Enter Debit Note'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-debitNoteGst-input">
                                        Debit Note GST {state.debitNoteGst.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-debitNoteGst-input"
                                        className={state.debitNoteGst.error ? "form-control is-invalid" : "form-control"}
                                        name={state.debitNoteGst.name}
                                        value={state.debitNoteGst.value}
                                        placeholder='Enter Debit Note GST'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-gstPending-input">
                                        GST Pending {state.gstPending.isRequired && <span className='text-danger'>*</span>}gs
                                    </label>
                                    <NumericFormat

                                        id="basicpill-gstPending-input"
                                        className={state.gstPending.error ? "form-control is-invalid" : "form-control"}
                                        name={state.gstPending.name}
                                        value={state.gstPending.value}
                                        placeholder='Enter GST Pending'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-balancePending-input">
                                        Balance Pending {state.balancePending.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-balancePending-input"
                                        className={state.balancePending.error ? "form-control is-invalid" : "form-control"}
                                        name={state.balancePending.name}
                                        value={state.balancePending.value}
                                        placeholder='Enter Balance Pending'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-netBalancePending-input">
                                        Net Balance Pending {state.netBalancePending.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-netBalancePending-input"
                                        className={state.netBalancePending.error ? "form-control is-invalid" : "form-control"}
                                        name={state.netBalancePending.name}
                                        value={state.netBalancePending.value}
                                        placeholder='Enter Net Balance Pending'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-tds-input">
                                        TDS {state.tds.isRequired && <span className='text-danger'>*</span>}
                                    </label>
                                    <NumericFormat

                                        id="basicpill-tds-input"
                                        className={state.tds.error ? "form-control is-invalid" : "form-control"}
                                        name={state.tds.name}
                                        value={state.tds.value}
                                        placeholder='Enter TDS'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <button
                                        type='submit'
                                        className="btn btn-success waves-effect btn-label waves-light me-2"
                                    >
                                        <i className="bx bx-check-double label-icon" />{" "}
                                        {props.buttonName}
                                    </button>
                                    <button
                                        type='reset'
                                        className="btn btn-danger waves-effect btn-label waves-light"
                                        onClick={() => { props.onCancel() }}
                                    >
                                        <i className="bx bx-block label-icon " /> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </React.Fragment>

    )
}

export default Form