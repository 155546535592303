import React, { useEffect, useState } from 'react'
import { validateFunctionalForm } from '../../utils';
import { v2_warehouse } from '../../actions/v2/warehouse';
import { v2_products, v2_sub_products } from '../../actions/v2/product';
import { v2_voucher_type } from '../../actions/v2/masterData';
import { NumericFormat } from 'react-number-format';

const Form = (props) => {
    const [state, setState] = useState({
        category: { name: 'category', value: props.data ? props.data.CATEGORY_ID : '', options: [], isRequired: true, error: '' },
        product: { name: 'product', value: props.data ? props.data.PRODUCT_ID : '', options: [], isRequired: true, error: '' },
        subProduct: { name: 'subProduct', value: props.data ? props.data.SUB_PRODUCT_ID : '', options: [], isRequired: true, error: '' },
        warehouse: { name: 'warehouse', value: props.data ? props.data.WAREHOUSE_ID : '', options: [], isRequired: true, error: '' },
        weight: { name: 'weight', value: props.data ? props.data.WEIGHT : '', isRequired: true, error: '' },
        remarks: { name: 'remarks', value: props.data ? props.data.REMARKS : '', isRequired: false, error: '' },
    });

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: prevState[name].isRequired && !value ? 'This field is required' : ''
            }
        }));
    };
    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                actualWeight: state.weight.value,
                productId: state.product.value,
                subProductId: state.subProduct.value,
                categoryId: state.category.value,
                warehouseId: state.warehouse.value,
                remarks: state.remarks.value
            }
            if (props.data) {
                model.id = props.data.ID
            }
            props.onSubmit(model)
        }
    }
    useEffect(() => {
        v2_voucher_type().then((res) => {
            if (res.status) {
                setOptions(state.category.name, res.result)
            }
        })

        v2_products().then((res) => {
            if (res.status) {
                setOptions(state.product.name, res.result)
            }
        })

        v2_warehouse().then((res) => {
            if (res.status) {
                setOptions(state.warehouse.name, res.result);
            }
        })

    }, [])

    useEffect(() => {
        if (state.product.value) {
            v2_sub_products(state.product.value).then((res) => {
                if (res.status) {
                    setOptions(state.subProduct.name, res.result)
                }
            })
        }
        else {
            setState(prevState => ({
                ...prevState,
                [state.subProduct.name]: {
                    ...prevState[state.subProduct.name],
                    value: ''
                }
            }));
            setOptions(state.subProduct.name, [])

        }
    }, [state.product.value])
    return (
        <React.Fragment>
            <h4 className="card-title mb-4">{props.title}</h4>
            <div id="basic-example">
                <section>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-product-input">
                                        Product <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className={state.product.error ? "form-select is-invalid" : "form-select"}
                                        id="basicpill-product-input"
                                        name={state.product.name}
                                        value={state.product.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Product</option>
                                        {state.product.options.map((item, index) => {
                                            return (
                                                <option value={item.ID}>{item.NAME}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-subProduct-input">
                                        Sub Product <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className={state.subProduct.error ? "form-select is-invalid" : "form-select"}
                                        id="basicpill-subProduct-input"
                                        name={state.subProduct.name}
                                        value={state.subProduct.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Sub Product</option>
                                        {state.subProduct.options.map((item, index) => {
                                            return (
                                                <option value={item.ID}>{item.NAME}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-warehouse-input">
                                        Warehouse
                                    </label>
                                    <select
                                        className={state.warehouse.error ? "form-select is-invalid" : "form-select"}
                                        id="basicpill-warehouse-input"
                                        name={state.warehouse.name}
                                        value={state.warehouse.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Warehouse</option>
                                        {state.warehouse.options.map((item, index) => {
                                            return (
                                                <option value={item.ID}>{item.NAME}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-phone-input">
                                        Category <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className={state.category.error ? "form-select is-invalid" : "form-select"}
                                        id="basicpill-category-input"
                                        name={state.category.name}
                                        value={state.category.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Category</option>
                                        {state.category.options.map((item, index) => {
                                            return (
                                                <option value={item.ID}>{item.NAME}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-weight-input">
                                        Weight (in Kg)<span className='text-danger'>*</span>
                                    </label>
                                    <NumericFormat
                                        id="basicpill-weight-input"
                                        className={state.weight.error ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter Weight (in Kg)"
                                        name={state.weight.name}
                                        value={state.weight.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-remarks-input">
                                        Remarks
                                    </label>
                                    <textarea
                                        row={2}
                                        id="basicpill-remarks-input"
                                        className={state.remarks.error ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter remarks"
                                        name={state.remarks.name}
                                        value={state.remarks.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <button
                                        type='submit'
                                        className="btn btn-success waves-effect btn-label waves-light me-2"
                                    >
                                        <i className="bx bx-check-double label-icon" />{" "}
                                        {props.buttonName}
                                    </button>
                                    <button
                                        type='reset'
                                        onClick={() => { props.onCancel() }}
                                        className="btn btn-danger waves-effect btn-label waves-light"
                                    >
                                        <i className="bx bx-block label-icon " /> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </React.Fragment>

    )
}

export default Form