import React from 'react';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Paginations from '../common/paginations';
import constant from '../../constant';

const List = ({ data, setCurrentPage, limit }) => {
    const { ledgers, total, page, totalPages } = data;

    const getBadgeClass = (paymentType) => {
        switch (paymentType) {
            case 'CREDIT':
                return 'badge bg-success'; // Green for Credit
            case 'DEBIT':
                return 'badge bg-danger'; // Red for Debit
            default:
                return 'badge bg-secondary'; // Grey for others
        }
    };

    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" style={{ width: 70 }}>Entry ID</th>
                            <th scope="col">Party Details</th>
                            <th scope="col">Payment Details</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ledgers?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle bg-primary text-white">{item.LEDGER_ID}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className="font-size-14 mb-1">
                                            <a href="javascript:void(0);" className="text-dark">
                                                {item.COMPANY_NAME}
                                            </a>
                                        </h5>
                                        <p className="text-muted">Invoice: {item.INVOICE_NUMBER || "Receipt"}</p>
                                    </td>
                                    <td>
                                        <p>Date: {item.DATE}</p>
                                        <p>
                                            Payment Type: <span className={getBadgeClass(item.PAYMENT_TYPE)}>{item.PAYMENT_TYPE}</span>
                                        </p>
                                        <p>Title: <strong>{item.TITLE}</strong></p>
                                        <p>Amount: <span className="text-success">₹{item.AMOUNT}</span></p>
                                        <p>
                                            <strong>Payment Mode:</strong>
                                            <span className="badge bg-info" style={{ marginLeft: '5px' }}>
                                                {item.PAYMENT_MODE}
                                            </span>
                                        </p>
                                    </td>
                                    <td>{item.CREATED_BY_NAME}</td>
                                    <td>
                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                            <li className="list-inline-item px-2">
                                                <Link to={{
                                                    pathname: constant.Authedcomponent.paymentEdit.url,
                                                    state: {
                                                        data: btoa(JSON.stringify(item)),
                                                        page: page
                                                    }
                                                }} title="Edit Ledger Entry">
                                                    <i className="bx bx-edit text-info" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <Paginations
                limit={limit}
                totalCount={total}
                range={5}
                currentPage={page}
                onPageChange={setCurrentPage}
            />
        </React.Fragment>
    );
};

export default withRouter(List);
