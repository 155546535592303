import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import constant from '../../constant';
import { getStorage, setSessionStorage } from '../../utils';
import { v1_modules } from '../../actions/v1/module';
import { v2_voucher_count } from '../../actions/v2/voucher';
import { v2_company_count } from '../../actions/v2/company';
import { v2_warehouse_count } from '../../actions/v2/warehouse';
import { v2_product_count } from '../../actions/v2/product';
import { v2_transporter_count } from '../../actions/v2/transporter';

const Sidebar = () => {
  const [activeMenus, setActiveMenus] = useState({});
  const location = useLocation();
  const [modules, setModules] = useState([]);
  const [count, setCount] = useState({ voucher: '', company: '', warehouse: '', product: '', transport: '' })
  useEffect(() => {
    const currentPath = location.pathname;

    // Load the menu state from session storage
    const savedActiveMenus = JSON.parse(sessionStorage.getItem('activeMenus')) || {};

    // Check if session storage has the saved state
    if (Object.keys(savedActiveMenus).length === 0) {
      // If session storage is cleared or empty, use the current path to set the active menu
      const findActiveMenu = (items) => {
        for (const item of items) {
          if (item.subItems) {
            for (const subItem of item.subItems) {
              if (subItem.url && subItem.url === currentPath) {
                return item.key;
              }
            }
          }
        }
        return null;
      };
      const activeMenuKey = menuItems.find(menu => findActiveMenu(menu.items))?.key;
      if (activeMenuKey) {
        savedActiveMenus[activeMenuKey] = true;
        sessionStorage.setItem('activeMenus', JSON.stringify(savedActiveMenus));
      }

      // Update the state with the active menu
      setActiveMenus(savedActiveMenus);
    } else {
      // If session storage is not cleared, just use the saved state
      setActiveMenus(savedActiveMenus);
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchedModules = JSON.parse(sessionStorage.getItem(constant.keys.userModule));
    if (!fetchedModules) {
      v1_modules().then((res) => {
        if (res.status) {
          setSessionStorage(constant.keys.userModule, res.result)
          setModules(JSON.parse(atob(res.result)));

        }
      })
    }
    else {
      setModules(JSON.parse(atob(fetchedModules)));
    }


    v2_voucher_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          voucher: res.result
        }));
      }
    });
    v2_company_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          company: res.result
        }));
      }
    });

    v2_warehouse_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          warehouse: res.result
        }));
      }
    });

    v2_product_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          product: res.result
        }));
      }
    });
    v2_transporter_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          transport: res.result
        }));
      }
    });

  }, [])


  const handleMenuClick = (menuKey) => {
    setActiveMenus(prev => {
      const updatedMenus = { ...prev, [menuKey]: !prev[menuKey] };
      // Save the state to session storage
      sessionStorage.setItem('activeMenus', JSON.stringify(updatedMenus));
      return updatedMenus;
    });
  };

  const renderSubMenuItems = (items) => (
    items.map((item, index) => (
      <li key={index}>
        {item.isLink ? (
          <Link
            to={item.url}
            className={location.pathname === item.url ? 'active' : ''}
          >
            {item.text}
          </Link>
        ) : (
          <span>{item.text}</span>
        )}
        {item.subItems && (
          <ul
            className={`sub-menu ${activeMenus[item.key] ? 'mm-show' : 'mm-collapse'}`}
            aria-expanded={activeMenus[item.key]}
          >
            {renderSubMenuItems(item.subItems)}
          </ul>
        )}
      </li>
    ))
  );

  const menuItems = [
    {
      moduleId: 0,
      title: 'Menu',
      items: [
        {
          key: 'dashboard',
          icon: 'bx bx-home-circle',  // Ensure proper prefix (e.g., 'bx')
          text: 'Dashboard',
          url: constant.Authedcomponent.dashboard.url,
          isLink: true
        },
        {
          key: 'voucher',
          icon: 'bx bxs-wallet', // Prefix with 'bx' or 'bxs' as needed
          text: 'Total Voucher',
          count: count.voucher,
          url: constant.Authedcomponent.listVoucher.url,
          isLink: true
        },
        {
          key: 'company',
          icon: 'bx bxs-building',
          text: 'Total Company',
          count: count.company,
          url: constant.Authedcomponent.listCompany.url,
          isLink: true
        },
        {
          key: 'warehouse',
          icon: 'bx bxs-store',
          text: 'Total Warehouse',
          count: count.warehouse,
          url: constant.Authedcomponent.listWareHouse.url,
          isLink: true
        },
        {
          key: 'product',
          icon: 'bx bxs-box',
          text: 'Total Product',
          count: count.product,
          url: constant.Authedcomponent.listProduct.url,
          isLink: true
        },
        {
          key: 'transport',
          icon: 'bx bxs-truck',
          text: 'Total Transport',
          count: count.transport,
          url: constant.Authedcomponent.listTransporter.url,
          isLink: true
        }
      ]
    },
    {
      moduleId: 3,
      title: 'Voucher Management',
      isLink: false,
      items: [
        {
          key: 'voucher',
          icon: 'bxs-wallet',
          text: 'Voucher Management',
          subItems: [
            { text: 'Create Voucher Entry', url: constant.Authedcomponent.createVoucher.url, isLink: true },
            { text: 'Voucher Entry List', url: constant.Authedcomponent.listVoucher.url, isLink: true },
            { text: 'Assign Voucher', url: constant.Authedcomponent.assignVoucher.url, isLink: true },
          ]
        }
      ]
    },
    {
      moduleId: 12,
      title: 'Payment Management',
      isLink: false,
      items: [
        {
          key: 'payment',
          icon: 'bxs-dollar-circle',
          text: 'Payment Management',
          subItems: [
            { text: 'New Entry', url: constant.Authedcomponent.paymentEntry.url, isLink: true },
            { text: 'Entry List', url: constant.Authedcomponent.paymentList.url, isLink: true },

          ]
        }
      ]
    },
    {
      moduleId: 4,
      title: 'Company Management',
      isLink: false,

      items: [
        {
          key: 'company',
          icon: 'bxs-building',
          text: 'Company Management',
          subItems: [
            { text: 'Create Company', url: constant.Authedcomponent.createCompany.url, isLink: true },
            { text: 'Company List', url: constant.Authedcomponent.listCompany.url, isLink: true }
          ]
        }
      ]
    },
    {
      moduleId: 5,
      title: 'Transport Management',
      isLink: false,

      items: [
        {
          key: 'transporter',
          icon: 'bxs-truck',
          text: 'Transport Management',
          subItems: [
            { text: 'Create Transporter', url: constant.Authedcomponent.createTransporter.url, isLink: true },
            { text: 'Transporter List', url: constant.Authedcomponent.listTransporter.url, isLink: true },
            { text: 'Export Transporter', url: '', isLink: true },
            { text: 'Add Vehicle', url: constant.Authedcomponent.createTransporterVehicle.url, isLink: true },
            { text: 'Vehicle List', url: constant.Authedcomponent.listTransporterVehicle.url, isLink: true }
          ]
        }
      ]
    },
    {
      moduleId: 6,
      title: 'Product Management',
      isLink: false,

      items: [
        {
          key: 'product',
          icon: 'bxs-box',
          text: 'Product Management',
          subItems: [
            { text: 'Create Product / Item', url: constant.Authedcomponent.createProduct.url, isLink: true },
            { text: 'Product Items List', url: constant.Authedcomponent.listProduct.url, isLink: true },
            { text: 'Create Sub-Product', url: constant.Authedcomponent.createSubProduct.url, isLink: true },
            { text: 'Sub-Product List', url: constant.Authedcomponent.listSubProduct.url, isLink: true }
          ]
        }
      ]
    },
    {
      moduleId: 7,
      title: 'Gate Management',
      isLink: false,

      items: [
        {
          key: 'gateEntry',
          icon: 'bxs-navigation',
          text: 'Gate Management',
          subItems: [
            { text: 'Create Gate Entry', url: constant.Authedcomponent.createGate.url, isLink: true },
            { text: 'Gate Entry List', url: constant.Authedcomponent.listGate.url, isLink: true }
          ]
        }
      ]
    },
    {
      moduleId: 8,
      title: 'Stock Management',
      isLink: false,

      items: [
        {
          key: 'stock',
          icon: 'bxs-package',
          text: 'Stock Management',
          subItems: [
            // { text: 'Stock Dashboard', url: '', isLink: true },
            { text: 'Create Stock', url: constant.Authedcomponent.createStock.url, isLink: true },
            { text: 'Stock List', url: constant.Authedcomponent.listStock.url, isLink: true },
            // { text: 'Export Stock Summary', url: '', isLink: true },
            // { text: 'Low Stock Alerts', url: '', isLink: true }
          ]
        }
      ]
    },
    {
      moduleId: 9,
      title: 'Warehouse Management',
      isLink: false,

      items: [
        {
          key: 'warehouse',
          icon: 'bxs-store',
          text: 'Warehouse Management',
          subItems: [
            { text: 'Create Warehouse', url: constant.Authedcomponent.createWareHouse.url, isLink: true },
            { text: 'Warehouse List', url: constant.Authedcomponent.listWareHouse.url, isLink: true }
          ]
        }
      ]
    },

    {
      moduleId: 11,
      title: 'Master Data Management',
      isLink: false,

      items: [
        {
          key: 'companyType',
          icon: 'bxs-data',
          text: 'Company Type',
          subItems: [
            { text: 'Create Company Type', url: constant.Authedcomponent.createCompanyTypeMasterData.url, isLink: true },
            { text: 'Company Type List', url: constant.Authedcomponent.listCompanyTypeMasterData.url, isLink: true }
          ]
        },
        {
          key: 'companyBusinessCategory',
          icon: 'bxs-data',
          text: 'Company Business Category',
          subItems: [
            { text: 'Create Category', url: constant.Authedcomponent.createCompanyBusinessCategoryMasterData.url, isLink: true },
            { text: 'Company Category List', url: constant.Authedcomponent.listCompanyBusinessCategoryMasterData.url, isLink: true }
          ]
        },
        {
          key: 'companyIndustry',
          icon: 'bxs-data',
          text: 'Company Industry',
          subItems: [
            { text: 'Create Industry', url: constant.Authedcomponent.createCompanyIndustryMasterData.url, isLink: true },
            { text: 'Company Industry List', url: constant.Authedcomponent.listCompanyIndustryMasterData.url, isLink: true }
          ]
        },
        {
          key: 'voucherType',
          icon: 'bxs-data',
          text: 'Voucher Type',
          subItems: [
            { text: 'Create Voucher Type', url: constant.Authedcomponent.createVoucherTypeMasterData.url, isLink: true },
            { text: 'Voucher Type List', url: constant.Authedcomponent.listVoucherTypeMasterData.url, isLink: true }
          ]
        },
        {
          key: 'state',
          icon: 'bxs-data',
          text: 'State',
          subItems: [
            { text: 'Create State', url: constant.Authedcomponent.createStateMasterData.url, isLink: true },
            { text: 'State List', url: constant.Authedcomponent.listStateMasterData.url, isLink: true }
          ]
        },
        {
          key: 'district',
          icon: 'bxs-data',
          text: 'District',
          subItems: [
            { text: 'Create District', url: constant.Authedcomponent.createDistrictMasterData.url, isLink: true },
            { text: 'District List', url: constant.Authedcomponent.listDistrictMasterData.url, isLink: true }
          ]
        },

      ]
    },
    {
      moduleId: 1,
      title: 'Administration',
      items: [
        {
          key: 'admin',
          icon: 'bxs-user',
          text: 'Admin User',
          subItems: [
            { text: 'Add Admin User', url: constant.Authedcomponent.addUser.url, isLink: true },
            { text: 'Admin User List', url: constant.Authedcomponent.listUser.url, isLink: true },
            { text: 'Add Roles', url: constant.Authedcomponent.addRole.url, isLink: true },
            { text: 'Roles List', url: constant.Authedcomponent.listRole.url, isLink: true },
            // { text: 'Admin User Log', url: '', isLink: true }
          ]
        }
      ]
    },
    {
      moduleId: 2,
      title: 'Modules',
      items: [
        {
          key: 'modules',
          icon: 'bxs-cog',
          text: 'Modules',
          subItems: [
            { text: 'Modules List', url: constant.Authedcomponent.listModules.url, isLink: true }
          ]
        }
      ]
    },
    {
      moduleId: 10,
      title: 'Report',
      items: [
        {
          key: 'voucher report',
          icon: 'bxs-report',
          text: 'Voucher Report',
          url: constant.Authedcomponent.voucherReport.url,
          isLink: true
        },
        {
          key: 'payment ledger',
          icon: 'bxs-report',
          text: 'Payment Ledger',
          url: constant.Authedcomponent.ledgerReport.url,
          isLink: true
        },
        {
          key: 'cash book',
          icon: 'bxs-report',
          text: 'Cash Book',
          url: constant.Authedcomponent.cashBookReport.url,
          isLink: true
        }
      ]
    },
    {
      moduleId: 0,
      title: 'Logs',
      items: [
        {
          key: 'activity logs',
          icon: 'bx-history',
          text: 'Activity Logs',
          url: constant.Authedcomponent.activityLog.url,
          isLink: true
        }
      ]
    },
  ];


  return (
    <div className="vertical-menu">
      <div data-simplebar="init" className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menuItems.map((section, sectionIndex) => {
              // Check if section.moduleId is available in the modules array
              if (modules.includes(section.moduleId) || section.moduleId === 0) {
                return (
                  <React.Fragment key={sectionIndex}>
                    <li className="menu-title">{section.title}</li>
                    {section.items.map((menu) => (
                      <li key={menu.key} className={activeMenus[menu.key] ? 'mm-active' : ''}>
                        {menu.isLink ? (
                          <Link
                            to={menu.url}
                          >
                            <i className={`bx ${menu.icon}`} />
                            {menu.count ? (
                              <span className={`badge rounded-pill float-end ${menu.count > 50 ? 'bg-success' : menu.count > 20 ? 'bg-warning' : 'bg-danger'}`}>
                                {menu.count}
                              </span>
                            ) : ''}
                            <span>{menu.text}</span>
                          </Link>
                        ) : (
                          <a
                            href="javascript:void(0);"
                            className="has-arrow waves-effect"
                            onClick={() => handleMenuClick(menu.key)}
                          >
                            <i className={`bx ${menu.icon}`} />
                            <span>{menu.text}</span>
                          </a>
                        )}
                        {menu.subItems && (
                          <ul
                            className={`sub-menu ${activeMenus[menu.key] ? 'mm-show' : 'mm-collapse'}`}
                            aria-expanded={activeMenus[menu.key]}
                          >
                            {renderSubMenuItems(menu.subItems)}
                          </ul>
                        )}
                      </li>
                    ))}
                  </React.Fragment>
                );
              }
              return null; // If section.moduleId is not available, return null to skip rendering
            })}

            <li className="text-danger">
              <Link to={constant.Authedcomponent.logout.url} className="waves-effect active">
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span className="text-danger">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>

  );
};

export default Sidebar;
