import React from 'react';
import Layout from '../../components/common/pageLayout';
import { toast } from 'react-toastify';
import { v3_add_new_entry, v3_edit_entry } from '../../actions/v3/ledgerAndCashbook';
import constant from '../../constant';
import Form from '../../components/LedgerAndCashbook/paymentEntry';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const PaymentEntry = (props) => {
    const location = useLocation();
    const { data, page } = location.state || {};
    const onSubmit = (model) => {
        v3_edit_entry(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message);
                props.history.push(constant.Authedcomponent.paymentList.url); // Redirect to payment list
            } else {
                toast.error(res.error);
            }
        });
    };

    const onCancel = () => {
        props.history.push(constant.Authedcomponent.paymentList.url); // Redirect to payment list on cancel
    };

    return (
        <React.Fragment>
            <Layout title={'Edit Payment Entry'}>
                <Form
                    onCancel={onCancel}
                    title={'Edit Payment Entry'}
                    onSubmit={onSubmit}
                    data={JSON.parse(atob(data))}
                    buttonName={'Save Entry'}
                />
            </Layout>
        </React.Fragment>
    );
};

export default PaymentEntry;
