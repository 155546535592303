import axios from "axios";
import { getAuthHeader } from "../../utils";

export const v3_add_voucher_amount_to_ledger = async (voucherId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/ledger/voucher/${voucherId}`;
    return axios.post(url, {}, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_add_new_entry = async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/ledger/entry`;
    return axios.post(url, payload, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_edit_entry = async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/ledger/entry`;
    return axios.put(url, payload, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_ledger_entry_list = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/ledger/entry/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
