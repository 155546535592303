import React, { useEffect, useState } from 'react';
import { validateFunctionalForm } from '../../utils';
import { v2_company_business_category, v2_company_industry, v2_company_type, v2_state_districts, v2_states } from '../../actions/v2/masterData';
import { toast } from 'react-toastify';

const Form = (props) => {
    const [state, setState] = useState({
        companyName: { name: 'companyName', value: props.data ? props.data.COMPANY_NAME : '', isRequired: true, error: '' },
        otherDetails: { name: 'otherDetails', value: props.data ? props.data.COMPANY_DETAIL : '', isRequired: false, error: '' },
        address: { name: 'address', value: props.data ? props.data.ADDRESS : '', isRequired: true, error: '' },
        state: { name: 'state', value: props.data ? props.data.STATE_ID : '', options: [], isRequired: true, error: '' },
        district: { name: 'district', value: props.data ? props.data.DISTRICT_ID : '', options: [], isRequired: true, error: '' },
        pinCode: { name: 'pinCode', value: props.data ? props.data.PIN_CODE : '', isRequired: true, error: '' },
        companyType: { name: 'companyType', value: props.data ? props.data.COMPANY_TYPE_ID : '', options: [], isRequired: true, error: '' },
        industry: { name: 'industry', value: props.data ? props.data.INDUSTRY_ID : '', options: [], isRequired: true, error: '' },
        businessCategory: { name: 'businessCategory', value: props.data ? props.data.BUSINESS_CATEGORY_ID : '', options: [], isRequired: true, error: '' },
        gstNumber: { name: 'gstNumber', value: props.data ? props.data.GST_NUMBER : '', isRequired: true, error: '' },
        panNumber: { name: 'panNumber', value: props.data ? props.data.PAN_NUMBER : '', isRequired: false, error: '' },
        contactPerson: { name: 'contactPerson', value: props.data ? props.data.CONTACT_PERSON : '', isRequired: true, error: '' },
        email: { name: 'email', value: props.data ? props.data.EMAIL : '', isRequired: false, error: '' },
        phone: { name: 'phone', value: props.data ? props.data.PHONE : '', isRequired: true, error: '' },
    });

    useEffect(() => {
        if (state.state.value !== '') {
            v2_state_districts(state.state.value).then((res) => {
                if (res.status) {
                    setOptions('district', res.result);
                } else {
                    toast.error(res.error);
                }
            });
        }
        else {
            setOptions('district', []);
        }
    }, [state.state.value])

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: prevState[name].isRequired && !value ? 'This field is required' : ''
            }
        }));
    };

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    };

    useEffect(() => {
        v2_states().then((res) => {
            if (res.status) {
                setOptions('state', res.result);
            } else {
                toast.error(res.error);
            }
        });

        v2_company_type().then((res) => {
            if (res.status) {
                setOptions('companyType', res.result);
            } else {
                toast.error(res.error);
            }
        });

        v2_company_industry().then((res) => {
            if (res.status) {
                setOptions('industry', res.result);
            } else {
                toast.error(res.error);
            }
        });

        v2_company_business_category().then((res) => {
            if (res.status) {
                setOptions('businessCategory', res.result);
            } else {
                toast.error(res.error);
            }
        });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                companyName: state.companyName.value,
                companyDetail: state.otherDetails.value,
                industry: state.industry.value,
                companyType: state.companyType.value,
                businessCategory: state.businessCategory.value,
                state: state.state.value,
                district: state.district.value,
                address: state.address.value,
                pinCode: state.pinCode.value,
                gst: state.gstNumber.value,
                pan: state.panNumber.value,
                email: state.email.value,
                phone: state.phone.value,
                contactPerson: state.contactPerson.value,
            };
            if (props.data) {
                model.id = props.data.ID;
                model.previousName = props.data.COMPANY_NAME
            }
            props.onSubmit(model);
        } else {
            toast.error('Please fill in all required fields.');
        }
    };

    return (
        <React.Fragment>
            <h4 className="card-title mb-4">{props.title}</h4>
            <div id="basic-example">
                <section>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="companyName">
                                        Company Name <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter Company Name'
                                        className={state.companyName.error ? "form-control is-invalid" : "form-control"}
                                        id="companyName"
                                        name={state.companyName.name}
                                        value={state.companyName.value}
                                        onChange={onChange}
                                    />
                                    {state.companyName.error && <div className="invalid-feedback">{state.companyName.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="companyType">
                                        Company Type <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className={state.companyType.error ? "form-select is-invalid" : "form-select"}
                                        id="companyType"
                                        name={state.companyType.name}
                                        value={state.companyType.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Choose Company Type</option>
                                        {state.companyType.options.map((item) => (
                                            <option key={item.ID} value={item.ID}>{item.NAME}</option>
                                        ))}
                                    </select>
                                    {state.companyType.error && <div className="invalid-feedback">{state.companyType.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="businessCategory">
                                        Business Category <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className={state.businessCategory.error ? "form-select is-invalid" : "form-select"}
                                        id="businessCategory"
                                        name={state.businessCategory.name}
                                        value={state.businessCategory.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Choose Business Category</option>
                                        {state.businessCategory.options.map((item) => (
                                            <option key={item.ID} value={item.ID}>{item.NAME}</option>
                                        ))}
                                    </select>
                                    {state.businessCategory.error && <div className="invalid-feedback">{state.businessCategory.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="industry">
                                        Industry <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className={state.industry.error ? "form-select is-invalid" : "form-select"}
                                        id="industry"
                                        name={state.industry.name}
                                        value={state.industry.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Choose Industry</option>
                                        {state.industry.options.map((item) => (
                                            <option key={item.ID} value={item.ID}>{item.NAME}</option>
                                        ))}
                                    </select>
                                    {state.industry.error && <div className="invalid-feedback">{state.industry.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="contactPerson">
                                        Contact Person <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter Contact Person Name'
                                        className={state.contactPerson.error ? "form-control is-invalid" : "form-control"}
                                        id="contactPerson"
                                        name={state.contactPerson.name}
                                        value={state.contactPerson.value}
                                        onChange={onChange}
                                    />
                                    {state.contactPerson.error && <div className="invalid-feedback">{state.contactPerson.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="phone">
                                        Phone  <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter Phone'
                                        className={state.phone.error ? "form-control is-invalid" : "form-control"}
                                        id="phone"
                                        name={state.phone.name}
                                        value={state.phone.value}
                                        onChange={onChange}
                                    />
                                    {state.phone.error && <div className="invalid-feedback">{state.phone.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter Email'
                                        className={state.email.error ? "form-control is-invalid" : "form-control"}
                                        id="email"
                                        name={state.email.name}
                                        value={state.email.value}
                                        onChange={onChange}
                                    />
                                    {state.email.error && <div className="invalid-feedback">{state.email.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="address">
                                        Address <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter Address'
                                        className={state.address.error ? "form-control is-invalid" : "form-control"}
                                        id="address"
                                        name={state.address.name}
                                        value={state.address.value}
                                        onChange={onChange}
                                    />
                                    {state.address.error && <div className="invalid-feedback">{state.address.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="state">
                                        State <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className={state.state.error ? "form-select is-invalid" : "form-select"}
                                        id="state"
                                        name={state.state.name}
                                        value={state.state.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Choose State</option>
                                        {state.state.options.map((item) => (
                                            <option key={item.ID} value={item.ID}>{item.NAME}</option>
                                        ))}
                                    </select>
                                    {state.state.error && <div className="invalid-feedback">{state.state.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="district">
                                        District <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className={state.district.error ? "form-select is-invalid" : "form-select"}
                                        id="district"
                                        name={state.district.name}
                                        value={state.district.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Choose District</option>
                                        {state.district.options.map((item) => (
                                            <option key={item.ID} value={item.ID}>{item.NAME}</option>
                                        ))}
                                    </select>
                                    {state.district.error && <div className="invalid-feedback">{state.district.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="pinCode">
                                        Pin Code <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter Pin Code'
                                        className={state.pinCode.error ? "form-control is-invalid" : "form-control"}
                                        id="pinCode"
                                        name={state.pinCode.name}
                                        value={state.pinCode.value}
                                        onChange={onChange}
                                    />
                                    {state.pinCode.error && <div className="invalid-feedback">{state.pinCode.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="gstNumber">
                                        GST Number <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter GST Number'
                                        className={state.gstNumber.error ? "form-control is-invalid" : "form-control"}
                                        id="gstNumber"
                                        name={state.gstNumber.name}
                                        value={state.gstNumber.value}
                                        onChange={onChange}
                                    />
                                    {state.gstNumber.error && <div className="invalid-feedback">{state.gstNumber.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="panNumber">
                                        PAN Number
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter PAN Number'
                                        className={state.panNumber.error ? "form-control is-invalid" : "form-control"}
                                        id="panNumber"
                                        name={state.panNumber.name}
                                        value={state.panNumber.value}
                                        onChange={onChange}
                                    />
                                    {state.panNumber.error && <div className="invalid-feedback">{state.panNumber.error}</div>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label htmlFor="OtherDetails">
                                        Other Details
                                    </label>
                                    <textarea
                                        id="OtherDetails"
                                        className={state.otherDetails.error ? "form-control is-invalid" : "form-control"}
                                        rows={3}
                                        placeholder="Enter Other Details"
                                        defaultValue={""}
                                        name={state.otherDetails.name}
                                        value={state.otherDetails.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <button
                                        type='submit'
                                        className="btn btn-success waves-effect btn-label waves-light me-2"
                                    >
                                        <i className="bx bx-check-double label-icon" />{" "}
                                        {props.buttonName}
                                    </button>
                                    <button
                                        type='reset'
                                        className="btn btn-danger waves-effect btn-label waves-light"
                                    >
                                        <i className="bx bx-block label-icon " /> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </section>
            </div>
        </React.Fragment>
    );
};

export default Form;
