import React, { useEffect, useState } from 'react'
import Layout from '../../components/common/pageLayout'
import { toast } from 'react-toastify'
import ListState from '../../components/LedgerAndCashbook/paymentEntryList'
import constant from '../../constant'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v3_ledger_entry_list } from '../../actions/v3/ledgerAndCashbook'
const List = (props) => {

    const location = useLocation();
    const { page } = location.state || {};
    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(page ? page : 1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        v3_ledger_entry_list(currentPage, limit).then((res) => {
            if (res.status) {
                setData(res.result);
                props.history.push(constant.Authedcomponent.paymentList.url, {
                    page: currentPage
                });
            }
            else {
                toast.error(res.error)
            }
        })
    }, [currentPage, limit])


    return (
        <Layout
            title={'Ledger Entry List'}
        >
            {data && <ListState
                limit={limit}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
        </Layout>
    )
}

export default List