const commoncomponent = {
    fourZeroFour: { url: '/404' },
    help: { url: '/help' },
}
const nonAuthedcomponent = {
    login: { url: '/login' },
    lockScreen: { url: '/lock' },

}

const Authedcomponent = {
    dashboard: { url: '/' },
    logout: { url: '/logout' },
    addUser: { url: '/user/add' },
    listUser: { url: '/user/list' },
    editUser: { url: '/user/edit' },

    activityLog: { url: '/activity/log' },


    addRole: { url: '/role/add' },
    listRole: { url: '/role/list' },
    editRole: { url: '/role/edit' },

    assignModules: { url: '/module/assign' },
    listModules: { url: '/module/list' },

    createCompanyTypeMasterData: { url: '/master-data-management/company-type/create' },
    listCompanyTypeMasterData: { url: '/master-data-management/company-type/list' },
    editCompanyTypeMasterData: { url: '/master-data-management/company-type/edit' },

    createStateMasterData: { url: '/master-data-management/state/create' },
    listStateMasterData: { url: '/master-data-management/state/list' },
    editStateMasterData: { url: '/master-data-management/state/edit' },

    createDistrictMasterData: { url: '/master-data-management/district/create' },
    listDistrictMasterData: { url: '/master-data-management/district/list' },
    editDistrictMasterData: { url: '/master-data-management/district/edit' },

    createCompanyBusinessCategoryMasterData: { url: '/master-data-management/company-business-category/create' },
    listCompanyBusinessCategoryMasterData: { url: '/master-data-management/company-business-category/list' },
    editCompanyBusinessCategoryMasterData: { url: '/master-data-management/company-business-category/edit' },


    createCompanyIndustryMasterData: { url: '/master-data-management/company-industry/create' },
    listCompanyIndustryMasterData: { url: '/master-data-management/company-industry/list' },
    editCompanyIndustryMasterData: { url: '/master-data-management/company-industry/edit' },


    createVoucherTypeMasterData: { url: '/master-data-management/voucher-type/create' },
    listVoucherTypeMasterData: { url: '/master-data-management/voucher-type/list' },
    editVoucherTypeMasterData: { url: '/master-data-management/voucher-type/edit' },


    createCompany: { url: '/company/create' },
    listCompany: { url: '/company/list' },
    editCompany: { url: '/company/edit' },

    createGate: { url: '/gate/create' },
    listGate: { url: '/gate/list' },
    editGate: { url: '/gate/edit' },

    createWareHouse: { url: '/warehouse/create' },
    listWareHouse: { url: '/warehouse/list' },
    editWareHouse: { url: '/warehouse/edit' },

    createProduct: { url: '/product/create' },
    listProduct: { url: '/product/list' },
    editProduct: { url: '/product/edit' },

    createSubProduct: { url: '/sub-product/create' },
    listSubProduct: { url: '/sub-product/list' },
    editSubProduct: { url: '/sub-product/edit' },

    createTransporter: { url: '/transporter/create' },
    listTransporter: { url: '/transporter/list' },
    editTransporter: { url: '/transporter/edit' },
    createTransporterVehicle: { url: '/transporter/vehicle/create' },
    listTransporterVehicle: { url: '/transporter/vehicle/list' },
    editTransporterVehicle: { url: '/transporter/vehicle/edit' },

    createVoucher: { url: '/voucher/create' },
    assignVoucher: { url: '/voucher/assign' },
    listVoucher: { url: '/voucher/list' },
    editVoucher: { url: '/voucher/edit' },
    voucherReport: { url: '/voucher/report' },

    paymentEntry: { url: '/payment/entry' },
    paymentList: { url: '/payment/list' },
    paymentEdit: { url: '/payment/edit' },




    createStock: { url: '/stock/create' },
    listStock: { url: '/stock/list' },
    editStock: { url: '/stock/edit' },


    ledgerReport: { url: '/ledger/report' },
    cashBookReport: { url: '/cashbook/report' },


}


const keys = {
    userDetails: 'eg_ud.ecogreen.com',
    userToken: 'eg_tk.ecogreen.com',
    userModule: 'eg_um.ecogreen.com'


}
export default { keys, commoncomponent, nonAuthedcomponent, Authedcomponent }