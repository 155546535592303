import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import Paginations from '../common/paginations';
import constant from '../../constant';
import moment from 'moment';
import { v2_users } from '../../actions/v2/user';
import { toast } from 'react-toastify';
import { v3_delete_voucher } from '../../actions/v3/voucherManagement';
import Swal from 'sweetalert2';


const List = ({ data, setCurrentPage, limit, onChangeAssignee,onDeleteVoucher }) => {

    const { vouchers, total, page, totalPages } = data;
    const [assignee, setAssignee] = useState([])

    useEffect(() => {
        v2_users().then((res) => {
            if (res.status) {
                setAssignee(res.result)
            }
        })
    }, [])
    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" style={{ width: 70 }}>
                                Voucher Id
                            </th>
                            <th scope="col">Voucher Details</th>
                            <th scope="col">Assignee</th>
                            <th scope="col">Modified By </th>
                            <th scope="col">Other Information </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vouchers?.map((item, index) => {
                            return (
                                <tr>
                                    <td>
                                        <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle">{item.ID}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <h4 className="font-size-14">
                                            RST Number : {item.RST_NUMBER}
                                        </h4>
                                        <h5 className="font-size-14">
                                            {item.INVOICE_NUMBER} ({item.VOUCHER_TYPE})
                                        </h5>
                                        <h5 className="font-size-14">
                                            Party Name : {item.PARTY_NAME}
                                        </h5>
                                        <p className="text-muted mb-0">{item.SUB_PRODUCT_NAME} ({item.PRODUCT_NAME})</p>
                                        <p className="text-muted mb-0">{item.CHALLAN_WEIGHT} Kg</p>
                                    </td>

                                    <td>
                                        <select
                                            className={'form-select'}
                                            value={item.ASSIGNEE}
                                            onChange={(e) => {
                                                const selectedUser = assignee.find(user => Number(user.USER_ID) === Number(e.target.value));
                                                onChangeAssignee(item, selectedUser);
                                            }}
                                        >
                                            <option value={''}>Select Assignee</option>
                                            {assignee.map((user) => (
                                                <option key={user.USER_ID} value={user.USER_ID}>
                                                    {user.FULL_NAME}
                                                </option>
                                            ))}
                                        </select>

                                    </td>
                                    {/* <td>
                                        {item.CREATED_BY_NAME}
                                        <p>{moment(item.CREATED_ON).format('DD MMM, YYYY on hh:mm A')}</p>
                                    </td> */}
                                    <td>
                                        {item.MODIFIED_BY_NAME}
                                        <p>{moment(item.MODIFIED_ON).format('DD MMM, YYYY on hh:mm A')}</p>

                                    </td>
                                    <td><button
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target={`#staticBackdrop${index}`}
                                        className="btn btn-success waves-effect btn-label waves-light"
                                    >
                                        <i className="bx bx-street-view label-icon " /> View Details{" "}
                                    </button>

                                        {/* STATIC BACKDROP MODAL */}
                                        <div
                                            className="modal fade"
                                            id={`staticBackdrop${index}`}
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-labelledby="staticBackdropLabel"
                                            aria-hidden="true"
                                        >
                                            <div
                                                className="modal-dialog modal-dialog-centered modal-lg"
                                                role="document"
                                            >
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="staticBackdropLabel">
                                                            RST Number : {item.RST_NUMBER} - Information
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        />
                                                    </div>
                                                    <div className="modal-body">
                                                        <table className="table table-striped mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Date</td>
                                                                    <td>{item.DATE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>RST Number</td>
                                                                    <td>{item.RST_NUMBER}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Voucher Type</td>
                                                                    <td>{item.VOUCHER_TYPE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Invoice Number</td>
                                                                    <td>{item.INVOICE_NUMBER}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Product</td>
                                                                    <td>{item.PRODUCT_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Sub Product</td>
                                                                    <td>{item.SUB_PRODUCT_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Vehicle</td>
                                                                    <td>{item.VEHICLE_NAME} ({item.VEHICLE_NUMBER})</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Challan Weight</td>
                                                                    <td>{item.CHALLAN_WEIGHT}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Invoice Rate</td>
                                                                    <td>{item.INVOICE_RATE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Warehouse</td>
                                                                    <td>{item.WAREHOUSE_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Gate</td>
                                                                    <td>{item.GATE_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Transporter</td>
                                                                    <td>{item.TRANSPORTER_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Assignee</td>
                                                                    <td>
                                                                        {assignee.find(user => user.USER_ID === item.ASSIGNEE)?.FULL_NAME || 'Not Assigned'}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Modified By</td>
                                                                    <td>{item.MODIFIED_BY_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Created By</td>
                                                                    <td>{item.CREATED_BY_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Created On</td>
                                                                    <td>{moment(item.CREATED_ON).format('DD MMM, YYYY on hh:mm A')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Modified On</td>
                                                                    <td>{moment(item.MODIFIED_ON).format('DD MMM, YYYY on hh:mm A')}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <p />
                                                    </div>

                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-light"
                                                            data-bs-dismiss="modal"
                                                        >
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* MODEL CLOSED */}

                                    </td>
                                    <td>
                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                            <li className="list-inline-item px-2">
                                                {item.EDITABLE ? <Link to={{
                                                    pathname: constant.Authedcomponent.editVoucher.url,
                                                    state: {
                                                        data: btoa(JSON.stringify(item)),
                                                        page: page
                                                    }  // Passing the entire `item` object as data
                                                }} title="Edit Voucher">
                                                    <i className="bx bx-edit" />
                                                </Link> : <p className='h6'>Not Editable</p>}
                                                &nbsp;
                                                <a
                                                    href="javascript:void(0);"
                                                    title="Delete Voucher"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'Are you sure?',
                                                            text: 'Do you really want to delete this voucher?',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#d33',
                                                            cancelButtonColor: '#3085d6',
                                                            confirmButtonText: 'Yes, delete it!',
                                                            cancelButtonText: 'Cancel'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                // Call the delete function here
                                                                onDeleteVoucher(item.ID)
                                                                
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <i className="bx bx-trash" />
                                                </a>
                                            </li>

                                        </ul>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            {total > 0 && <Paginations
                limit={limit}
                totalCount={total}
                range={5}
                currentPage={page}
                onPageChange={setCurrentPage}
            />}
        </React.Fragment>


    )
}

export default withRouter(List)