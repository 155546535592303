import React, { useEffect, useState } from 'react'
import { validateFunctionalForm } from '../../utils';
import { v2_warehouse } from '../../actions/v2/warehouse';

const Form = (props) => {
    const [state, setState] = useState({
        name: { name: 'name', value: props.data ? props.data.NAME : '', isRequired: true, error: '' },
        warehouse: { name: 'warehouse', value: props.data ? props.data.WAREHOUSE_ID : '', options: [], isRequired: true, error: '' },
        otherDetails: { name: 'otherDetails', value: props.data ? props.data.DETAIL : '', isRequired: false, error: '' },
    })
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: prevState[name].isRequired && !value ? 'This field is required' : ''
            }
        }));
    };
    useEffect(() => {
        v2_warehouse().then((res) => {
            if (res.status) {
                setOptions('warehouse', res.result);
            }
        })
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                warehouseId: state.warehouse.value,
                gateName: state.name.value,
                gateDetail: state.otherDetails.value,
            }
            if (props.data) {
                model.id = props.data.ID
            }
            props.onSubmit(model)
        }
    }

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    };
    return (
        <React.Fragment>
            <h4 className="card-title mb-4">{props.title}</h4>
            <div id="basic-example">
                <section>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-warehouse-input">
                                        Warehouse <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className={state.warehouse.error ? "form-control is-invalid" : "form-control"}
                                        id="basicpill-warehouse-input"
                                        name={state.warehouse.name}
                                        value={state.warehouse.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Warehouse</option>
                                        {state.warehouse.options.map((item) => {
                                            return (
                                                <option value={item.ID}>{item.NAME  }</option>

                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-firstname-input">
                                        Gate Name <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className={state.name.error ? "form-control is-invalid" : "form-control"}
                                        id="basicpill-firstname-input"
                                        placeholder="Enter Gate Name"
                                        name={state.name.name}
                                        value={state.name.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-address-input">
                                        Other Details
                                    </label>
                                    <textarea
                                        id="basicpill-address-input"
                                        className={state.otherDetails.error ? "form-control is-invalid" : "form-control"}
                                        rows={2}
                                        placeholder="Enter Other Details"
                                        defaultValue={""}
                                        name={state.otherDetails.name}
                                        value={state.otherDetails.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <button
                                        type='submit'
                                        className="btn btn-success waves-effect btn-label waves-light me-2"
                                    >
                                        <i className="bx bx-check-double label-icon" />{" "}
                                        {props.buttonName}
                                    </button>
                                    <button
                                        type='reset'
                                        onClick={() => { props.onCancel() }}
                                        className="btn btn-danger waves-effect btn-label waves-light"
                                    >
                                        <i className="bx bx-block label-icon " /> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </React.Fragment>

    )
}

export default Form