import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ListState from '../../components/LedgerAndCashbook/cashbookReport'
import constant from '../../constant'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v2_ledger_cashbook_report } from '../../actions/v2/ledgerAndCashbook'
import moment from 'moment'
import { v2_companies } from '../../actions/v2/company'
import { validateFunctionalForm } from '../../utils'
const List = (props) => {

    // State and useEffect hooks
    const location = useLocation();
    const { page } = location.state || {};
    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const [limit, setLimit] = useState(10);
    const [searchModel, setSearchModel] = useState({
        startDate: '',
        endDate: '',
        party: '',
    });

    useEffect(() => {
        if (searchModel.startDate && searchModel.endDate) {
            v2_ledger_cashbook_report(searchModel, currentPage, limit).then((res) => {
                if (res.status) {
                    setData(res.result);
                    props.history.push(constant.Authedcomponent.cashBookReport.url, {
                        page: currentPage,
                    });
                } else {
                    toast.error(res.error);
                }
            });
        }
    }, [currentPage, limit, searchModel.startDate, searchModel.endDate]);

    useEffect(() => {
        if (searchModel.startDate && searchModel.endDate) {
            setCurrentPage(1);
            v2_ledger_cashbook_report(searchModel, 1, limit).then((res) => {
                if (res.status) {
                    setData(res.result);
                    props.history.push(constant.Authedcomponent.cashBookReport.url, {
                        page: currentPage,
                    });
                } else {
                    toast.error(res.error);
                }
            });
        }
    }, [searchModel, limit]);

    return (
        <div className="page-content">
            <div className="container-fluid">
                {/* start page title */}
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Report Management</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <a href="javascript: void(0);">DashBoard</a>
                                    </li>
                                    <li className="breadcrumb-item active">Report Management</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end page title */}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <SearchOptions onSubmit={setSearchModel}
                                    data={data}
                                    limit={limit}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                {data && (
                                    <ListState
                                        limit={limit}
                                        data={data}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default List

const SearchOptions = (props) => {
    const [state, setState] = useState({
        startDate: { name: 'startDate', value: moment().subtract(6, 'months').format('YYYY-MM-DD'), isRequired: true, error: '' },
        endDate: { name: 'endDate', value: moment().format('YYYY-MM-DD'), isRequired: true, error: '' },
        party: { name: 'party', value: '', options: [], isRequired: true, error: '' },
    });

    const [records, setRecords] = useState([]);
    const [partyDetails, setPartyDetails] = useState(null);
    const [carryForward, setCarryForward] = useState({ creditTotal: 0, debitTotal: 0 });
    const [loading, setLoading] = useState(false);

    const fetchAllRecord = async () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                startDate: state.startDate.value,
                endDate: state.endDate.value,
                party: state.party.value,
            };

            let currentPage = 1;
            let allLedgers = [];
            let totalPages = 1;

            setLoading(true); // Start loading

            try {
                while (currentPage <= totalPages) {
                    const response = await v2_ledger_cashbook_report(model, currentPage, props.limit);

                    // Ensure ledgers exists and is an array
                    const ledgers = Array.isArray(response.result.ledgers) ? response.result.ledgers : [];
                    let { totalPages: totalPages, partyDetails: partyDetails } = response.result;

                    if (currentPage === 1) {
                        setPartyDetails(partyDetails);
                    }

                    // Append current page ledgers to allLedgers
                    allLedgers = [...allLedgers, ...ledgers];
                    totalPages = totalPages || 1;

                    currentPage++;
                }

                setRecords(allLedgers);
                // Calculate carry forward totals based on fetched records
                const creditTotal = allLedgers
                    .filter(record => record.PAYMENT_TYPE === 'CREDIT')
                    .reduce((sum, record) => sum + record.AMOUNT, 0);

                const debitTotal = allLedgers
                    .filter(record => record.PAYMENT_TYPE === 'DEBIT')
                    .reduce((sum, record) => sum + record.AMOUNT, 0);

                setCarryForward({ creditTotal, debitTotal });

            } catch (error) {
                console.error('Error fetching records:', error);
                // Handle the error as needed, such as showing a user-friendly message
            } finally {
                setLoading(false); // End loading
            }
        }
    };

    useEffect(() => {
        const fetchCompanies = async () => {
            const res = await v2_companies();
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    party: { ...prevState.party, options: res.result }
                }));
            }
        };
        fetchCompanies();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: { ...prevState[name], value }
        }));
    };

    const handleSearch = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                startDate: state.startDate.value,
                endDate: state.endDate.value,
                party: state.party.value,
            };
            props.onSubmit(model);
        }
    };

    const handleReset = () => {
        setState({
            startDate: { name: 'startDate', value: moment().subtract(6, 'months').format('YYYY-MM-DD'), isRequired: true, error: '' },
            endDate: { name: 'endDate', value: moment().format('YYYY-MM-DD'), isRequired: true, error: '' },
            party: { name: 'party', value: '', options: [], isRequired: true, error: '' },
        });
        setRecords([]); // Clear records on reset
        setPartyDetails(null); // Clear party details on reset
    };

    const handlePrint = () => {
        const contentToPrint = document.getElementById("ledgerPrintSection").innerHTML;
        const printWindow = window.open('', '_blank', 'width=800,height=600');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Ledger</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        .text-center { text-align: center; }
                        .text-end { text-align: right; }
                        table { width: 100%; border-collapse: collapse; }
                        th, td { border: 1px solid #000; padding: 8px; text-align: left; }
                    </style>
                </head>
                <body>
                    ${contentToPrint}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    return (
        <form className="row gy-12 gx-12 align-items-center">
            <div className="col-sm-4 mb-2">
                <label htmlFor="companySelect">Party</label>
                <div className="input-group">
                    <select
                        id="companySelect"
                        name="party"
                        className={state.party.error && !state.party.value ? "form-select is-invalid" : "form-select"}
                        value={state.party.value}
                        onChange={handleChange}
                    >
                        <option value="">Select Party</option>
                        {state.party.options.map(option => (
                            <option key={option.ID} value={option.ID}>{option.COMPANY_NAME}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Date Range */}
            <div className="col-sm-4 mb-2">
                <label>Date Range</label>
                <div className="row">
                    <div className="col">
                        <div className="input-group">
                            <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                className="form-control"
                                value={state.startDate.value}
                                onChange={handleChange}
                                placeholder="From"
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="input-group">
                            <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                className="form-control"
                                value={state.endDate.value}
                                onChange={handleChange}
                                placeholder="To"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Buttons */}
            <div className="col-sm-4 mb-2 d-flex align-items-center" style={{ top: '14px' }}>
                <button
                    type="button"
                    className="btn btn-success waves-effect btn-label waves-light me-2"
                    onClick={handleSearch}
                >
                    <i className="bx bx-search label-icon" /> Search
                </button>
                <button
                    type="button"
                    className="btn btn-outline-danger me-2"
                    onClick={handleReset}
                >
                    <i className="bx bx-reset label-icon" />
                    Reset
                </button>
                <button
                    type="button"
                    className="btn btn-outline-info me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop4"
                    onClick={fetchAllRecord} // Fetch records before showing modal
                >
                    View & Download <i className="bx bx-download label-icon" />
                </button>

                {/* STATIC BACKDROP MODAL */}
                <div
                    className="modal fade"
                    id="staticBackdrop4"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content" id="ledgerPrintSection">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    {partyDetails ? partyDetails.COMPANY_NAME : 'Loading...'}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                {/* Replace hard-coded details with dynamic data */}
                                {partyDetails && (
                                    <>
                                        <div className="ledger-header text-center">
                                            <h5>{partyDetails.COMPANY_NAME}</h5>
                                            <p>{partyDetails.ADDRESS}</p>
                                            <p>Contact: {partyDetails.PHONE}</p>
                                        </div>

                                        <div className="ledger-period">
                                            <p className='text-center'>{moment(state.startDate.value).format('DD MMM YYYY')} to {moment(state.endDate.value).format('DD MMM YYYY')}</p>
                                            <p>Page 1</p>
                                        </div>

                                        <table className="table table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th></th>
                                                    <th>Particulars</th>
                                                    <th>Vch No.</th>
                                                    <th>Credit</th>
                                                    <th>Debit</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {records.map((record, index) => {
                                                    debugger
                                                    return (<tr key={index}>
                                                        <td>{record.DATE}</td>
                                                        <td>{record.VOUCHER_TYPE === 'CREDIT' ? 'Cr ' : 'Dr '}</td>
                                                        <td>{record.TITLE}</td>
                                                        <td>{record.INVOICE_NUMBER || 'Receipt'}</td>
                                                        <td>{record.PAYMENT_TYPE === 'CREDIT' ? record.AMOUNT.toLocaleString() : ''}</td>
                                                        <td>{record.PAYMENT_TYPE === 'DEBIT' ? record.AMOUNT.toLocaleString() : ''}</td>

                                                    </tr>)
                                                }

                                                )}
                                            </tbody>
                                        </table>
                                        {/* Display carry forward totals */}
                                        <div className="carry-forward text-end mt-3">
                                            <p>Total Credit: {carryForward.creditTotal.toLocaleString()}</p>
                                            <p>Total Debit: {carryForward.debitTotal.toLocaleString()}</p>
                                        </div>


                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={handlePrint}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

