import { Route, Switch } from "react-router-dom";
import React from 'react';
import constant from "./constant";
import { getStorage } from "./utils";
// import FourZeroFour from "./components/common/404";
import Login from './pages/auth/login'
import Lock from './pages/auth/lock'
import Header from './components/common/header'
import SideBar from './components/common/sidebar'
import Footer from './components/common/footer'
import Dashboard from './pages/DashBoard/index'

// Administrator

import AddUser from './pages/Administration/users/add'
import EditUser from './pages/Administration/users/edit'
import ListUser from './pages/Administration/users/list'
import AddRole from './pages/Administration/role/add'
import EditRole from './pages/Administration/role/edit'
import ListRole from './pages/Administration/role/list'
import ListModule from './pages/Administration/module/list'
import AssignModule from './pages/Administration/module/roles_assign'
import ActivityLogs from './pages/Administration/logs/list'


// Administrator

// Master Data

import AddCompanyTypeMasterData from './pages/MasterData/CompanyType/add'
import ListCompanyTypeMasterData from './pages/MasterData/CompanyType/list'
import EditCompanyTypeMasterData from './pages/MasterData/CompanyType/edit'

import AddStateMasterData from './pages/MasterData/State/add'
import ListStateMasterData from './pages/MasterData/State/list'
import EditStateMasterData from './pages/MasterData/State/edit'

import AddDistrictMasterData from './pages/MasterData/District/add'
import ListDistrictMasterData from './pages/MasterData/District/list'
import EditDistrictMasterData from './pages/MasterData/District/edit'

import AddCompanyBusinessCategoryMasterData from './pages/MasterData/CompanyBusinessCategory/add'
import ListCompanyBusinessCategoryMasterData from './pages/MasterData/CompanyBusinessCategory/list'
import EditCompanyBusinessCategoryMasterData from './pages/MasterData/CompanyBusinessCategory/edit'


import AddCompanyIndustryMasterData from './pages/MasterData/CompanyIndustry/add'
import ListCompanyIndustryMasterData from './pages/MasterData/CompanyIndustry/list'
import EditCompanyIndustryMasterData from './pages/MasterData/CompanyIndustry/edit'


import AddVoucherTypeMasterData from './pages/MasterData/VoucherType/add'
import ListVoucherTypeMasterData from './pages/MasterData/VoucherType/list'
import EditVoucherTypeMasterData from './pages/MasterData/VoucherType/edit'


// Master Data


//Vouchers

import AddVoucher from './pages/Voucher/add'
import EditVoucher from './pages/Voucher/edit'
import AssignVoucher from './pages/Voucher/assign'
import VoucherEntryList from './pages/Voucher/entryList'
import VoucherEntryReport from './pages/Voucher/report'



//Vouchers



//Company Management

import AddCompany from './pages/Company/add'
import EditCompany from './pages/Company/edit'
import ListCompany from './pages/Company/list'

//Company Management



//Gate Management

import AddGate from './pages/Gate/add'
import EditGate from './pages/Gate/edit'
import ListGate from './pages/Gate/list'

//Gate Management


//WareHouse Management

import AddWareHouse from './pages/WareHouse/add'
import EditWareHouse from './pages/WareHouse/edit'
import ListWareHouse from './pages/WareHouse/list'

//WareHouse Management

//Product Management

import AddProduct from './pages/Product/add'
import EditProduct from './pages/Product/edit'
import ListProduct from './pages/Product/list'
import AddSubProduct from './pages/Product/subProduct/add'
import EditSubProduct from './pages/Product/subProduct/edit'
import ListSubProduct from './pages/Product/subProduct/list'

//Product Management



//Transporter Management

import AddTransporter from './pages/Transporter/add'
import EditTransporter from './pages/Transporter/edit'
import ListTransporter from './pages/Transporter/list'
import AddTransporterVehicle from './pages/Transporter/Vehicle/add'
import EditTransporterVehicle from './pages/Transporter/Vehicle/edit'
import ListTransporterVehicle from './pages/Transporter/Vehicle/list'

//Transporter Management


//Stock Management

import AddStock from './pages/Stock/add'
import ListStock from './pages/Stock/list'
import EditStock from './pages/Stock/edit'


//Stock Management


//Payment Management

import PaymentEntry from './pages/LedgerAndCashbook/paymentEntry'
import PaymentEntryList from './pages/LedgerAndCashbook/entryList'
import PaymentEntryEdit from './pages/LedgerAndCashbook/entryEdit'
import LedgerReport from './pages/LedgerAndCashbook/ledgerReport'
import CashbookReport from './pages/LedgerAndCashbook/cashbookReport'





//Payment Management

import Logout from './pages/auth/logout'



const Router = () => {
    return (
        <React.Fragment>
            <Switch>
                <NonAuthedRoute exact={true} hideHeader={true} hideFooter={true} hideSidebar={false} path={constant.nonAuthedcomponent.login.url} component={Login} />
                <NonAuthedRoute exact={true} hideHeader={true} hideFooter={true} hideSidebar={false} path={constant.nonAuthedcomponent.lockScreen.url} component={Lock} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.dashboard.url} component={Dashboard} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.addUser.url} component={AddUser} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listUser.url} component={ListUser} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editUser.url} component={EditUser} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.addRole.url} component={AddRole} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editRole.url} component={EditRole} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listRole.url} component={ListRole} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listModules.url} component={ListModule} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.assignModules.url} component={AssignModule} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.activityLog.url} component={ActivityLogs} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createCompanyTypeMasterData.url} component={AddCompanyTypeMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listCompanyTypeMasterData.url} component={ListCompanyTypeMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editCompanyTypeMasterData.url} component={EditCompanyTypeMasterData} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createStateMasterData.url} component={AddStateMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listStateMasterData.url} component={ListStateMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editStateMasterData.url} component={EditStateMasterData} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createDistrictMasterData.url} component={AddDistrictMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listDistrictMasterData.url} component={ListDistrictMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editDistrictMasterData.url} component={EditDistrictMasterData} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createCompanyBusinessCategoryMasterData.url} component={AddCompanyBusinessCategoryMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listCompanyBusinessCategoryMasterData.url} component={ListCompanyBusinessCategoryMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editCompanyBusinessCategoryMasterData.url} component={EditCompanyBusinessCategoryMasterData} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createCompanyIndustryMasterData.url} component={AddCompanyIndustryMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listCompanyIndustryMasterData.url} component={ListCompanyIndustryMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editCompanyIndustryMasterData.url} component={EditCompanyIndustryMasterData} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createVoucherTypeMasterData.url} component={AddVoucherTypeMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listVoucherTypeMasterData.url} component={ListVoucherTypeMasterData} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editVoucherTypeMasterData.url} component={EditVoucherTypeMasterData} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createCompany.url} component={AddCompany} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listCompany.url} component={ListCompany} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editCompany.url} component={EditCompany} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createGate.url} component={AddGate} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listGate.url} component={ListGate} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editGate.url} component={EditGate} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createWareHouse.url} component={AddWareHouse} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listWareHouse.url} component={ListWareHouse} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editWareHouse.url} component={EditWareHouse} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createProduct.url} component={AddProduct} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listProduct.url} component={ListProduct} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editProduct.url} component={EditProduct} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createSubProduct.url} component={AddSubProduct} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listSubProduct.url} component={ListSubProduct} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editSubProduct.url} component={EditSubProduct} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createTransporter.url} component={AddTransporter} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listTransporter.url} component={ListTransporter} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editTransporter.url} component={EditTransporter} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createTransporterVehicle.url} component={AddTransporterVehicle} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listTransporterVehicle.url} component={ListTransporterVehicle} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editTransporterVehicle.url} component={EditTransporterVehicle} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createVoucher.url} component={AddVoucher} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editVoucher.url} component={EditVoucher} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.assignVoucher.url} component={AssignVoucher} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listVoucher.url} component={VoucherEntryList} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={true} path={constant.Authedcomponent.voucherReport.url} component={VoucherEntryReport} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.createStock.url} component={AddStock} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.listStock.url} component={ListStock} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.editStock.url} component={EditStock} />

                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.paymentEntry.url} component={PaymentEntry} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.paymentList.url} component={PaymentEntryList} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.paymentEdit.url} component={PaymentEntryEdit} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.ledgerReport.url} component={LedgerReport} />
                <PrivateRoute exact={true} hideHeader={false} hideFooter={false} hideSidebar={false} path={constant.Authedcomponent.cashBookReport.url} component={CashbookReport} />

                <Route exact={true} path={constant.Authedcomponent.logout.url} component={Logout} />
                {/* <Route component={FourZeroFour} /> */}
            </Switch>

        </React.Fragment>

    )
}


export default Router;

const NonAuthedRoute = (props) => {
    const token = getStorage(constant.keys.userToken);

    if (!token) {
        const urlKeys = Object.entries(constant.nonAuthedcomponent);
        const path = props.path;
        const match = urlKeys.filter((i) => i[1].url === path);
        if (match.length > 0 && match[0][1].url === path) {
            return (
                <Route exact={true} path={props.path} component={props.component} />
            )
        }
        else {
            window.location.href = constant.nonAuthedcomponent.login.url

        }
    }
    else {
        window.location.href = constant.Authedcomponent.dashboard.url

    }

}


const PrivateRoute = (props) => {
    const token = getStorage(constant.keys.userToken);
    if (token) {
        const urlKeys = Object.entries(constant.Authedcomponent);
        const path = props.path;
        const match = urlKeys.filter((i) => i[1].url === path);
        if (props.hideSidebar) {
            document.body.classList.add('sidebar-enable', 'vertical-collpsed');
        }
        else {
            document.body.classList.remove('sidebar-enable', 'vertical-collpsed');

        }
        if (match.length > 0 && match[0][1].url === path) {
            return (
                <React.Fragment>
                    <div id='layout-wrapper'>
                        {!props.hideHeader && <Header
                            sidebarStatus={props.hideSidebar ? true : false}
                        />}
                        {<SideBar />}
                        <div className="main-content">
                            <Route exact={true} path={props.path} component={props.component} />
                            {!props.Footer && <Footer />}
                        </div>

                    </div>

                </React.Fragment>
            )
        }
        else {
            window.location.href = constant.Authedcomponent.dashboard.url

        }
    }
    else {
        window.location.href = constant.nonAuthedcomponent.login.url

    }

}
